import React from 'react';

import style from './customInput.module.scss';

interface CustomInputProps {
  type?: string;
  label?: string;
  placeholder?: string;
  onChange?: any;
  styles?: string;
  searchIcon?: any;
  className?: string;
  value?: string | number;
}
export const CustomInput: React.FC<CustomInputProps> = ({
  type,
  label,
  placeholder,
  searchIcon,
  className,
  onChange,
  value
}) => (
  <div className={style.customInputContainer}>
    {label && (
      <label className={style.customInputFieldLabel} htmlFor={`inputId-${label}`}>
        {label}
      </label>
    )}
    <div className={style.inputContainer}>
      <input
        id={`inputId-${label}`}
        type={type}
        className={className ? className : style.inputField}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {searchIcon && <span className={style.searchIcon}>{searchIcon}</span>}
    </div>
  </div>
);
