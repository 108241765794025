import React from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';

import style from './customSelect.module.scss';

export interface OptionType {
  id?: number;
  value: string;
  label: string;
}

interface CustomSelectProps {
  options?: OptionType[];
  placeholder?: string;
  components?: any;
  label?: string;
  addProject?: boolean;
  setshowFields?: any;
  addProjectHandlers?: any;
  setSelectedOption?: (option: any) => void;
  selectedOption?: any;
  menuIsOpen?: boolean;
  setMenuIsOpen?: (value: boolean | ((prevState: boolean) => boolean)) => void;
  modalType?: any;
}

const customStyles: StylesConfig<OptionType, false, GroupBase<OptionType>> = {
  control: (provided, state) => ({
    ...provided,
    borderBottom: state.menuIsOpen ? 'none' : '1px solid #868E96',
    borderBottomRightRadius: state.menuIsOpen ? 0 : provided.borderBottomRightRadius,
    borderBottomLeftRadius: state.menuIsOpen ? 0 : provided.borderBottomLeftRadius,
    fontFamily: 'Gotham500',
    fontSize: '14px',
    boxShadow: 'none',
    borderColor: '#868E96',
    '&:hover': {
      borderColor: '#868E96'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontFamily: 'Gotham500',
    fontSize: '14px'
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontFamily: 'Gotham500',
    fontSize: '14px'
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: 'left',
    marginTop: 0,
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    fontFamily: 'Gotham500',
    fontSize: '14px',
    width: '99.8%',
    marginLeft: '1px'
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    borderBottom: '1px solid #ccc',
    marginTop: '-0.3rem',
    backgroundColor: state.isSelected ? 'black' : 'white',
    cursor: 'pointer',
    fontFamily: 'Gotham500',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    },
    height: '46px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center'
  })
};

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options = [],
  placeholder,
  components,
  label,
  setshowFields,
  setSelectedOption,
  selectedOption,
  menuIsOpen,
  setMenuIsOpen,
  modalType
}) => {
  const handleChange = (option: OptionType | null): void => {
    if (option && setSelectedOption) {
      setSelectedOption(option);

      if (modalType === 'add-to-project') {
        setshowFields({
          addProject: true
        });
      }
    }
  };

  return (
    <div className={style.customSelectContainer}>
      {label && (
        <label htmlFor={`inputId-${label}`} className={style.customSelectLabel}>
          {label}
        </label>
      )}
      <Select
        menuIsOpen={menuIsOpen}
        options={options}
        components={components ? { Menu: components } : undefined}
        placeholder={placeholder}
        styles={customStyles}
        id={`inputId-${label}`}
        defaultInputValue=""
        value={selectedOption}
        onChange={handleChange}
        onMenuOpen={() => setMenuIsOpen && setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen && setMenuIsOpen(false)}
      />
    </div>
  );
};
