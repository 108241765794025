import { useEffect, useState } from 'react';

import { CarbonDioxide } from '../../assets/svgs/carbonDioxide';
import Certifications from '../../assets/svgs/certification';
import { CheckMarkIcon } from '../../assets/svgs/checkMarkIcon';
import Circularity from '../../assets/svgs/circularity';
import { EcomedesLogo } from '../../assets/svgs/ecomedesLogo';
import Energy from '../../assets/svgs/energy';
import LeedCredits from '../../assets/svgs/leedCredits';
import { Materials } from '../../assets/svgs/materials';
import Medal from '../../assets/svgs/medal';
import Rating from '../../assets/svgs/rating';
import { ThumbsDownIcon } from '../../assets/svgs/thumbsDownIcon';
import { ThumbsUpIcon } from '../../assets/svgs/thumbsUpIcon';
import { WaterDropIcon } from '../../assets/svgs/waterDrop';
import Wind from '../../assets/svgs/wind';
import useElementSize from '../../hooks/useElementSize';
import HubSpotForm from '../shared/HubspotForm';
import IconBox from '../shared/IconBox/iconBox';
import { ModalComponent } from '../shared/Modal/Modal';

import GreenTagsContainer from './greenTags/GreenTagsContainer';

import style from './aiSummary.module.scss';
const GREEN_TAGS_SMALL_SCREEEN = 5;
const GREEN_TAGS_DESKTOP_SCREEEN = 10;
const MOBILE_SCREEN_WIDTH = 900;

interface AIInfterface {
  ai_summary?: string;
  brandIdent?: string;
  sku?: string;
  siteIdent?: string;
  productUrl?: string;
  green_tags?: IGreenTag[];
}
interface IGreenTag {
  badges?: string[];
  icon_name?: string;
  label?: string;
}

export const greenTagsIcons: { [key: string]: JSX.Element } = {
  ratings_icon: <Rating greenTags />,
  certifications_icon: <Certifications greenTags />,
  leed_credits_icon: <LeedCredits />,
  circularity_icon: <Circularity greenTags />,
  indoor_air_quality_icon: <Wind />,
  carbon_icon: <CarbonDioxide />,
  material_health_icon: <Medal />,
  mindful_materials_icon: <Materials />,
  water_icon: <WaterDropIcon greenTags />,
  energy_icon: <Energy greenTags />
};

export const AISummary = ({ data }: { data?: AIInfterface }): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCrossIcon, setshowCrossIcon] = useState(true);
  const [ref, size] = useElementSize();
  const [isMobileScreen, setMobileScreen] = useState(false);

  useEffect(() => {
    if ((size.width as any) > MOBILE_SCREEN_WIDTH) {
      setMobileScreen(true);
    }
  }, [size?.width]);

  const toggle = (): void => setIsModalOpen(!isModalOpen);
  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <div className={style.aiSummaryContainer}>
      {data?.ai_summary && (
        <div
          className={
            !!data?.green_tags?.length
              ? `${style.aiSummaryHighlights} ${style.aiSummaryHighlightsBorder}`
              : `${style.aiSummaryHighlights}`
          }>
          <div className={style.aiSummaryHighlightsHeader}>
            <div className={style.ecomedesIcon}>
              <IconBox icon={<EcomedesLogo />} />
            </div>
            <div className={style.highlightsLabel}>
              <p className={style.highlightsLabelText}>Sustainability Highlights</p>
            </div>
          </div>
          <div className={style.aiSummaryDescription}>{data?.ai_summary || ''}</div>
          <div className={style.aiSummaryFooter}>
            <p className={style.aiSummaryFooterText}>
              *This description was generated by AI. Was this helpful?
            </p>
            <div className={style.footerIconSection}>
              <div className={style.footerIcons}>
                <div onClick={() => toggle()}>
                  <ThumbsUpIcon />
                </div>
                <div onClick={() => toggle()}>
                  <ThumbsDownIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${style.aiSummaryTagsSection}
          ${!data?.ai_summary ? style.fullWidth : ''}
        `}>
        <div className={style.aiSummaryTagsSubSection}>
          {data?.ai_summary ? (
            ''
          ) : (
            <div className={style.aiSummaryHighlightsHeader}>
              <div className={style.ecomedesIcon}>
                <IconBox icon={<EcomedesLogo />} />
              </div>
              <div className={style.highlightsLabel}>
                <p className={style.highlightsLabelText}>Sustainability Highlights</p>
              </div>
            </div>
          )}

          <section
            ref={ref}
            className={
              data?.ai_summary
                ? style.greeTagsContainer
                : `${style.greeTagsContainer} ${style.greenTagsBorderTop}`
            }>
            {!!data?.green_tags &&
              data?.green_tags?.map((greenTags, index) => (
                <div
                  className={`${style.greenTags} ${data?.green_tags && index === data?.green_tags?.length - 1 ? style.greenTagsLastChild : ''}`}>
                  <div key={index} className={`${style.tagsContainer}`}>
                    <div className={style.lensInfo}>
                      <div className={style.greenTagsIcon}>
                        {greenTags?.icon_name ? greenTagsIcons[greenTags?.icon_name] : ''}
                      </div>
                      <p className={style.tagsLabel}>{greenTags.label}</p>
                    </div>
                  </div>
                  <div className={style.greeTagsItems}>
                    <BadgeList
                      data={greenTags?.badges}
                      ai_summary={data?.ai_summary || ''}
                      mobileScreen={isMobileScreen}
                    />
                  </div>
                </div>
              ))}
          </section>
        </div>
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        setIsModalOpen={closeModal}
        showCrossIcon={showCrossIcon}
        modalBody={
          <HubSpotForm
            formId="5cd7805b-7c6e-45fb-a695-4c7d0f753d67"
            toggle={toggle}
            setIsModalOpen={closeModal}
            setshowCrossIcon={setshowCrossIcon}
            aiSummarydata={data}
          />
        }
      />
    </div>
  );
};

const BadgeList: React.FC<{ data?: string[]; ai_summary?: string; mobileScreen?: any }> = ({
  data = [],
  ai_summary,
  mobileScreen
}) => {
  const size = mobileScreen ? GREEN_TAGS_DESKTOP_SCREEEN : GREEN_TAGS_SMALL_SCREEEN;
  const [isShow, setIsShow] = useState(false);
  const [isGreenTagNumbers, setIsGreenTagNumbers] = useState(size);

  useEffect(() => {
    setIsGreenTagNumbers(isShow ? data?.length : size);
  }, [data, isShow, size]);

  return (
    <div>
      <GreenTagsContainer>
        {data?.length <= size &&
          data &&
          data.map((el: any, index) => (
            <div
              className={`${style.innerGreenTageRowTags} ${ai_summary ? '' : style.greenTagWidth}`}
              key={index}>
              <div className={style.innerGreenTageRowTagsLabel}>
                <div className={style.greenTagCheckMark}>
                  <CheckMarkIcon />{' '}
                </div>
                <p style={{ fontSize: '14px', width: 'max-content', margin: 0, padding: 0 }}>
                  {el}
                </p>
              </div>
            </div>
          ))}
        {data?.length > size &&
          data &&
          data.slice(0, isGreenTagNumbers)?.map((el: any, index) => (
            <div
              className={`${style.innerGreenTageRowTags} ${ai_summary ? '' : style.greenTagWidth}`}
              key={index}>
              <div className={style.innerGreenTageRowTagsLabel}>
                <div className={style.greenTagCheckMark}>
                  <CheckMarkIcon />{' '}
                </div>
                <p style={{ fontSize: '14px' }}>{el}</p>
              </div>
            </div>
          ))}
      </GreenTagsContainer>
      {data?.length > size && (
        <div
          className={style.expandItemText}
          onClick={() => {
            setIsShow(!isShow);
          }}>
          {isShow ? 'Show Less' : 'Show More'}
        </div>
      )}
    </div>
  );
};
