import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AISearchTitleIcon } from '../../assets/svgs/aiSearchTitleIcon';
import { Container } from '../shared/Container';
import CustomButton from '../shared/CustomButton';
import { ModalComponent } from '../shared/Modal/Modal';

import ModalBody from './AISearchModal/modalBody';
import ModalFooter from './AISearchModal/modalFooter';
import ModalHeader from './AISearchModal/modalHeader';
import { AISearchData } from './dummyData';

import style from './aiSearch.module.scss';

const AISearch: React.FC<any> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [initialReponse, setInitialResponse] = useState([]);
  const [productData, setProductData] = useState([]);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const openModal = (): void => {
    setIsModalOpen(true);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
  };
  // will be replace once starting geting actual data
  const mockFetchProductIds = async (): Promise<any> => {
    await new Promise((resolve) => setTimeout(resolve, 500));

    return [1, 2, 3];
  };
  // will be replace once starting geting actual data
  const mockProduct = async (): Promise<any> => {
    await new Promise((resolve) => setTimeout(resolve, 3000));

    return AISearchData;
  };
  // to fetch products
  const fetchProduct = useCallback(
    async (ids?: string[]): Promise<void> => {
      try {
        if (!data) {
          console.error('Data is not available');

          return;
        }

        const response = await mockProduct();
        const actualResponceresponse = await data.fetchProducts(ids);

        if (response) {
          setProductData(response);
          setInitialResponse([]);
        }

        console.log(ids, actualResponceresponse, 'Prodcut Response');
      } catch (error) {
        console.error('Error fetching product IDs:', error);
      }
    },
    [data]
  );
  // to fetch an ids
  const fetchProductIds = useCallback(
    async (questions?: string): Promise<void> => {
      try {
        if (!data) {
          console.error('Data is not available');

          return;
        }

        const response = await mockFetchProductIds();
        const actualProductIdsResponse = await data.fetchProductIds(questions);

        if (response) {
          setInitialResponse(response);
          setProductData([]);
        }

        console.log("Products", questions, actualProductIdsResponse, 'Semantic Search FE result');
      } catch (error) {
        console.error('Error fetching product IDs:', error);
      }
    },
    [data]
  );

  useEffect(() => {
    if (!!initialReponse.length) {
      fetchProduct();
    }
  }, [fetchProduct, initialReponse.length]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.body.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <Container>
      <div className={style.aiSearchContainer}>
        <div className={style.aiSearchIconContainer}>
          <div className={style.iconContainer}>
            <AISearchTitleIcon />
          </div>
        </div>
        <div className={style.aiSearchContent}>
          <h3 className={style.aiSearchContentTitle}>New AI search (BETA)</h3>
          <p className={style.aiSearchContentDescription}>
            Try our new AI Search. You can type in what you want and get the products quickly. This
            is an experimental feature and we’d love your feedback to improve it.
          </p>
        </div>
        <div className={style.aiSearchButtons} onClick={openModal}>
          <CustomButton
            onClick={()=> fetchProductIds("Products")}
            label="AI SEARCH"
            className={style.aiSearchButtonsStyle}
          />
        </div>
      </div>
      <ModalComponent
        ref={modalRef}
        isOpen={isModalOpen}
        setIsModalOpen={closeModal}
        showCrossIcon={false}
        modalHeader={<ModalHeader />}
        modalBody={<ModalBody initialData={initialReponse} productData={productData} />}
        modalFooter={<ModalFooter closeModal={closeModal} />}
      />
    </Container>
  );
};

export default AISearch;
