/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: any) => {
          onFormSubmitted: (callback: () => void) => void;
          onFormReady?: (callback: ($form: JQuery<HTMLElement>) => void) => void;
        };
      };
    };
  }
}

interface FormConfiguration {
  onFormReady?: ($form: JQuery<HTMLElement>, aiSummarydata: any) => void;
}

const formConfigurations: Record<string, FormConfiguration> = {
  '5cd7805b-7c6e-45fb-a695-4c7d0f753d67': {
    onFormReady: ($form, aiSummarydata) => {
      $form
        .find('input[name="ai_summary"]')
        .val(aiSummarydata?.ai_summary || '')
        .change();
      $form
        .find('input[name="brand_name"]')
        .val(aiSummarydata?.brand_ident || '')
        .change();
      $form
        .find('input[name="sku"]')
        .val(aiSummarydata?.sku || '')
        .change();
      $form
        .find('input[name="site_ident"]')
        .val(aiSummarydata?.site_ident || '')
        .change();
      $form
        .find('input[name="product_page"]')
        .val(aiSummarydata?.product_url || '')
        .change();
      $form.find('input[name="product_category"]').val('Product Category').change();
    }
  }
};

interface HubSpotFormProps {
  formId: string;
  toggle?: () => void;
  setIsModalOpen: () => void;
  setshowCrossIcon?: (action: boolean) => void;
  aiSummarydata?: {
    ai_summary?: string;
    brand_ident?: string;
    sku?: string;
    site_ident?: string;
    product_url?: string;
  };
}

const HubSpotForm: React.FC<HubSpotFormProps> = ({
  formId,
  toggle,
  setIsModalOpen,
  setshowCrossIcon,
  aiSummarydata
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    const onScriptLoad = (): void => {
      setLoading(false);

      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '617005',
          formId: formId,
          target: '#hubspotForm',
          onFormReady: formConfigurations[formId]?.onFormReady
            ? ($form: JQuery<HTMLElement>) =>
                formConfigurations[formId]?.onFormReady?.($form, aiSummarydata)
            : undefined,
          onFormSubmitted: () => {
            if (setshowCrossIcon) {
              setshowCrossIcon(false);
            }

            setTimeout(() => {
              setIsModalOpen();
            }, 2000);
          },
          onFormError: (error: Error) => {
            console.error('Form submission error:', error);
          }
        });
      }
    };

    script.addEventListener('load', onScriptLoad);

    return () => {
      script.removeEventListener('load', onScriptLoad);
    };
  }, [
    formId,
    toggle,
    setIsModalOpen,
    setshowCrossIcon,
    aiSummarydata?.ai_summary,
    aiSummarydata?.brand_ident,
    aiSummarydata?.sku,
    aiSummarydata?.site_ident,
    aiSummarydata?.product_url,
    aiSummarydata
  ]);

  return <div id="hubspotForm">{loading && <p>Loading...</p>}</div>;
};

export default HubSpotForm;
