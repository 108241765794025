import React, { useState } from 'react';

import SearchIcon from '../../../../assets/svgs/searchIcon';
import Accordion from '../../../shared/AccordianSearch/Accordion';
import { CustomInput } from '../../../shared/CustomInput';
import Skeleton from '../../../shared/Skeleton/skeleton';
import AiSearchAccordionHeader from '../aiSearchAccordionHeader/index';

import style from '../../aiSearch.module.scss';

const ModalBody: React.FC<{ initialData?: any; productData?: any }> = ({
  initialData,
  productData
}) => {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = (): void => {
    setShowMore(true);
  };
  const displayedProducts =
    showMore || productData?.length <= 3 ? productData : productData.slice(0, 3);

  return (
    <div>
      <div className={style.modalBodyContainer}>
        <CustomInput
          searchIcon={<SearchIcon />}
          placeholder="Drywall with an EPD, recycled content and LEED credits from USG and Certainteed"
          className={style.inputField}
        />
        <div className={style.searchButton}>Search</div>
      </div>

      <div className={style.searchResultsCount}>
        Search results ({initialData?.length || productData?.length})
      </div>
      <section style={{ maxHeight: '400px', overflow: 'auto' }}>
        {!!displayedProducts?.length &&
          displayedProducts.map((item: any) => (
            <section key={item.id} className={style.searchBodyContainer}>
              <Accordion
                className={style.aiSearchAccordion}
                isAiSearch
                headerContent={<AiSearchAccordionHeader items={item} />}>
                <section className={style.searchBodyheader}>
                  <div className={style.searchBodyheaderContent}>
                    <div className={style.searchBodyheaderContentCategory}>
                      <p className={style.searchBodyheaderContentLable}>Category:</p>
                      <p className={style.searchBodyheaderContentValue}>BUILDING FURNISHING</p>
                    </div>
                    <div className={style.searchBodyheaderContentCategory}>
                      <p className={style.searchBodyheaderContentLable}>Sub Category:</p>
                      <p className={style.searchBodyheaderContentValue}>FURNISHING</p>
                    </div>
                  </div>
                  <p className={style.searchBodyheaderContentP}>{item?.description}</p>
                </section>
              </Accordion>
            </section>
          ))}
      </section>

      {initialData?.map((el: any, index: number) => (
        <section key={index} className={style.searchBodyContainer}>
          <Accordion className={style.aiSearchAccordion} isAiSearch headerContent={<Skeleton />}>
            <div></div>
          </Accordion>
        </section>
      ))}

      {productData && productData.length > 3 && !showMore && (
        <div className={style.aiSearchShowMore} onClick={handleShowMore}>
          Show more
        </div>
      )}
    </div>
  );
};

export default ModalBody;
