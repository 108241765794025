import { forwardRef, ReactNode } from 'react';
import { RxCross2 } from 'react-icons/rx';

import styles from './Modal.module.scss';

export const ModalComponent = forwardRef<
  HTMLDivElement,
  {
    isOpen?: boolean;
    modalHeader?: ReactNode;
    modalBody?: ReactNode;
    modalFooter?: ReactNode;
    setIsModalOpen?: () => void;
    showCrossIcon?: boolean;
    className?: string;
  }
>(
  (
    {
      isOpen = false,
      modalBody,
      modalHeader,
      modalFooter,
      setIsModalOpen,
      showCrossIcon = false,
      className = ''
    },
    ref
  ): JSX.Element => (
    <div className={`${isOpen ? styles.overlay : styles.hideModal}`}>
      <div className={`${className ? className : styles.customModal}`} ref={ref}>
        {showCrossIcon && (
          <div data-testid="modal-overlay" className={styles.crossIcon} onClick={setIsModalOpen}>
            <RxCross2 fontSize={20} />
          </div>
        )}
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>{modalFooter}</ModalFooter>
      </div>
    </div>
  )
);

const ModalHeader = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={styles.modalHeader || ''}>{children}</div>
);
const ModalBody = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={styles.modalBody || ''}>{children}</div>
);
const ModalFooter = ({ children }: { children: ReactNode }): JSX.Element => (
  <div className={styles.modalFooter || ''}>{children}</div>
);

ModalComponent.displayName = 'ModalComponent';
