/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';

import { AddIcon } from '../../assets/svgs/addIcon';
import { AddProjectIcon } from '../../assets/svgs/addProject';
import { DownloadButtonIcon } from '../../assets/svgs/downloadButtonIcon';
import { DownloadPdf } from '../../assets/svgs/downloadPdf';
import LocationIcon from '../../assets/svgs/location';
import { MenuIcon } from '../../assets/svgs/menuIcon';
import { QueryIcon } from '../../assets/svgs/queryIcon';
import Rating from '../../assets/svgs/rating';
import WhiteRatings from '../../assets/svgs/whiteRatings';
import { baseURL } from '../../utils/baseUrl';
import { handlePDFDownload } from '../../utils/pdf';
import { generatePageTemplate } from '../ProjectEvaluation/Header';
import AccordionRow from '../shared/AccordionRow/AccordionRow';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import RCButton from '../shared/Button/Button';
import ButtonContainer from '../shared/ButtonContainer';
import { Container } from '../shared/Container';
import CustomButton from '../shared/CustomButton';
import { OptionType } from '../shared/CustomSelect';
import HubSpotForm from '../shared/HubspotForm';
import IconBox from '../shared/IconBox/iconBox';
import Loader from '../shared/Loader/loader';
import { ModalComponent } from '../shared/Modal/Modal';
import ProductContributionGridHeader from '../shared/ProductContributionGridHeader/ProductContributionGridHeader';

import HubspotFormContainer from './HubspotFormContainer';
import ModalBody from './modalBody';
import { ModalFooter } from './modalFooter';
import { ModalHeader } from './modalHeader';

import style from './productDetails.module.scss';

interface AccordionData {
  id: number;
  value: boolean;
}

const buttonInfoIcons: { [key: string]: JSX.Element } = {
  plus: <AddIcon />,
  download: <DownloadButtonIcon />,
  certificate: <WhiteRatings />,
  comment: <AddIcon />
};
const menuIcons: { [key: string]: JSX.Element } = {
  plus: <MenuIcon />,
  comment: <MenuIcon />
};

interface ProductDetailProps {
  data: Record<string, any> | undefined;
  pdfRef: RefObject<HTMLDivElement>;
}
interface AddProjectHandlersProps {
  addToProject: (params: any) => Promise<any>;
  getProjects: () => Promise<any>;
  createProject: (params: any) => Promise<any>;
}
export const ProductDetail: React.FC<ProductDetailProps> = ({ data, pdfRef }) => {
  const [accordionContentShow, setaccordionContentShow] = useState<AccordionData[]>([]);
  const [modalType, setModalType] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [addProjectHandlers, setAddProjectHandlers] = useState<AddProjectHandlersProps | null>(
    null
  );
  const [showFields, setshowFields] = useState({
    addProject: false,
    ratingSytem: false
  });
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [addProjectName, setAddProjectName] = useState<string>('');
  const [addProjectZipcode, setAddProjectZipcode] = useState<string | number>('');
  const modalRef = useRef<HTMLDivElement | null>(null);
  const actionButtionList =
    (data &&
      data['action-buttons'] &&
      data['action-buttons']?.filter((el: any) => el.text !== 'Get PDF')) ||
    [];
  const onHeaderClick = (index: number): any => {
    setaccordionContentShow((prevData) =>
      prevData?.map((data) => ({
        ...data,
        value: Number(data.id) === Number(index) ? !data.value : data.value
      }))
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.body.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);
  useEffect(() => {
    if (data) {
      setaccordionContentShow(
        data.lens?.map((_: any, index: number) => ({
          id: index,
          value: true
        })) || []
      );

      if (data && data['action-buttons']) {
        setAddProjectZipcode(
          data['action-buttons']?.find((item: any) => item?.type === 'document-contributions')
            ?.zipcode || ''
        );
      }
    }
  }, [data]);
  const getAddProjectHandlers = (handlers: any): void => {
    setAddProjectHandlers(handlers);
  };
  const closeModal = (): void => {
    setSelectedOption(null);
    setModalType(null);
    setIsModalOpen(false);
    setshowFields({
      addProject: false,
      ratingSytem: false
    });
    setAddProjectHandlers(null);
  };
  const onSubmitHandler = async (): Promise<any> => {
    if (modalType === 'add-to-project') {
      if (
        selectedOption &&
        selectedOption?.value !== 'Create new project' &&
        addProjectHandlers?.addToProject
      ) {
        const selectedProjectId = selectedOption?.id;
        const params = { 'selected-project': selectedProjectId };
        const response = await addProjectHandlers?.addToProject(params);

        if (response) {
          setSelectedOption(null);
          closeModal();
        }
      }

      if (addProjectHandlers?.createProject && addProjectName && addProjectZipcode) {
        const params = {
          'project-name': addProjectName,
          'project-zipcode': addProjectZipcode
        };

        addProjectHandlers
          ?.createProject(params)
          .then(() => {
            console.log('Project created and product added successfully.');
            setAddProjectName('');
            setAddProjectZipcode('');
            closeModal();
          })
          .catch((error) => {
            console.error('Error creating project:', error);
          });
      }
    }

    if (modalType === 'document-contributions') {
      if (data && data['rating-systems'] && selectedOption) {
        const id = data['rating-systems']?.find(
          (data: any) => data?.name === selectedOption?.value
        )?.id;

        if (id) {
          let pathname = window.location.pathname;

          pathname = pathname.replace('product', 'products');

          window.location.href = baseURL({ id, pathname });
        }
      }
    }
  };
  interface ModalContent {
    header: ReactNode;
    body: ReactNode;
    footer: ReactNode;
  }

  type ModalContentMap = {
    [key: string]: ModalContent;
  };

  const modalContent: ModalContentMap = {
    'add-to-project': {
      header: (
        <ModalHeader
          Icon={<AddProjectIcon />}
          label="Add to project"
          description="Create a new project or add to an existing project"
          closeIcon={<RxCross2 fontSize={20} />}
          setIsModalOpen={() => closeModal()}
        />
      ),
      body: (
        <ModalBody
          addProject={showFields.addProject}
          setshowFields={setshowFields}
          addProjectHandlers={addProjectHandlers}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          projectName={addProjectName}
          setProjectName={setAddProjectName}
          postalCode={addProjectZipcode}
          setPostalCode={setAddProjectZipcode}
          modalType={modalType || ''}
        />
      ),
      footer: <ModalFooter onClose={() => closeModal()} onSubmit={onSubmitHandler} />
    },
    'contact-manufacturer': {
      header: (
        <ModalHeader
          label={'Contact Fanimation'}
          closeIcon={<RxCross2 fontSize={20} />}
          setIsModalOpen={() => closeModal()}
          className={style.modalHeader}
        />
      ),
      body: (
        <HubspotFormContainer className={style.hubspotFormContainer}>
          <HubSpotForm
            setIsModalOpen={() => closeModal()}
            formId={'dca4dac0-f43f-4510-a5fc-2416b2bfd6cc'}
          />
        </HubspotFormContainer>
      ),
      footer: null
    },
    'document-contributions': {
      header: (
        <ModalHeader
          Icon={<Rating productDetail />}
          label="Credit Evaluation"
          description="Check credits for LEED, WELL, Living Building Challenge and other ratings systems and standards. "
          closeIcon={<RxCross2 fontSize={20} />}
          setIsModalOpen={() => closeModal()}
        />
      ),
      body: (
        <ModalBody
          ratings
          addProject={showFields.addProject}
          ratingsData={(data && data['rating-systems']) || []}
          setSelectedOption={setSelectedOption}
          modalType={modalType || ''}
          postalCode={addProjectZipcode}
          setPostalCode={setAddProjectZipcode}
        />
      ),
      footer: <ModalFooter onClose={() => closeModal()} onSubmit={onSubmitHandler} />
    }
  };
  const openModal = (modalType: string): void => {
    setModalType(modalType);

    if (modalType === 'add-to-project') {
      setshowFields({
        addProject: true,
        ratingSytem: false
      });
    } else if (modalType === 'document-contributions') {
      setshowFields({
        addProject: false,
        ratingSytem: true
      });
    }

    setIsModalOpen(true);
  };
  const getModalContent = (): ModalContent =>
    modalType ? modalContent[modalType] : { header: null, body: null, footer: null };

  return (
    <Container>
      <nav>
        <Breadcrumb breadcrumbs={data?.breadcrumbs || []} />
      </nav>
      <header>
        <div className={style.productEvaluationSection}>
          <div className={style.productMedaDataSection}>
            <Container>
              <div className={style.productDetails}>
                <div className={style.productImgSection}>
                  <img src={data?.product?.image} alt="product" className={style.productImg} />
                </div>
                <div className={style.productMetaDataContainer}>
                  <div className={style.productMetaData}>
                    <div className={style.productCategory}>
                      {!!data?.product['key-features'] &&
                        data.product['key-features']?.map((feature: any) => (
                          <div className={style.productCategorykeys}>
                            <div className={style.productCategoryInformation}>{feature?.name}:</div>
                            <div className={style.productCategoryValues}>
                              {Array.isArray(feature?.value) ? (
                                <div>{feature.value.join(', ')}</div>
                              ) : (
                                <div>{feature?.value}</div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className={style.productBrandName}>
                      {data?.product['brand-name'] || ''}
                    </div>
                    <div className={style.productName}>{data?.product?.name || ''}</div>
                    <div className={style.productDescription}>
                      {data?.product?.description || ''}
                    </div>
                  </div>
                  <div className={style.downloadButtons}>
                    <RCButton
                      onClick={() => {
                        handlePDFDownload(
                          pdfRef,
                          setIsPdfLoading,
                          generatePageTemplate,
                          `${data?.product['brand-name']?.toLowerCase()}-${data?.product.sku}-ecomedes-evaluation`
                        );
                      }}
                      className={`${style.pdfButton} ${style.downloadButtonContent} hidePdfElements`}
                      variant="primary">
                      <span className={style.downloadPdfText}>DOWNLOAD PDF</span>
                      {isPdfLoading ? <Loader /> : <DownloadPdf productEval />}
                    </RCButton>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </header>
      <div className={style.actionButtonContainer}>
        <Container>
          <div className={`${style.actionButtonsSection} pdfactionButtonSection`}>
            {data &&
              actionButtionList?.map((info: any) => (
                <ButtonContainer
                  Icon={buttonInfoIcons[info.icon] ?? ''}
                  text={info.text ?? ''}
                  MenuIcon={menuIcons[info.icon] ?? ''}
                  openModal={info?.type !== 'download-pdf' ? openModal : null}
                  modalType={info?.type !== 'download-pdf' && info?.type}
                  handlers={info?.handlers || ''}
                  projectHandlers={getAddProjectHandlers}
                />
              ))}
          </div>
        </Container>
      </div>

      <main>
        <div className={style.ratingSystem}>
          <Container>
            <div className={style.contactUsSection}>
              <IconBox icon={<Rating productDetail />} className={style.iconBox} />
              <div className={style.contactUsDetails}>
                <div className={style.contactUsDetailsHeader}>
                  <h3 className={style.contactUsDetailsTitle}>Rating System Credits</h3>
                  <div style={{ marginTop: '10px' }}>
                    <QueryIcon />
                  </div>
                </div>
                <p className={style.contactUsDetailsDescription}>
                  Check credits for LEED, WELL, Living Building Challenge and other ratings systems
                  and standards. To access sign-in or register for a free account.
                </p>
              </div>
              <div className={`${style.contactUsButtons} pdfContentHide`}>
                <div onClick={() => openModal('contact-us')}>
                  <CustomButton label="EVALUATE CREDITS" className={style.contactUsButtonsStyle} />
                </div>
              </div>
            </div>
            <h3 className={style.ratingSystemHeader}>Details</h3>
            <div className={style.projectLocationSection}>
              <div className={style.projectLocation}>
                <div className={style.locationIcon}>
                  <LocationIcon />
                </div>
                <p className={style.projectLocationLabel}>
                  Is this your project location? Edit the location at the top of the page for plant
                  specific information
                </p>
              </div>
              <p
                className={
                  style.projectPostalCode
                }>{`Postal Code: ${addProjectZipcode ? `[${addProjectZipcode}]` : ''} `}</p>
            </div>
            {!!data?.lens &&
              data?.lens?.map((lensValue: any, lensIndex: number): any => (
                <div style={{ marginBottom: '2rem' }}>
                  <div key={`lens-${lensIndex}`}>
                    <ProductContributionGridHeader
                      key={`header-${lensIndex}`}
                      name={lensValue?.name || ''}
                      image={lensValue?.image || ''}
                      notes={lensValue?.notes || ''}
                      lensDetails={lensValue?.attributes || []}
                      productPage
                      onHeaderClick={onHeaderClick}
                      index={lensIndex}
                      accordionContentShow={accordionContentShow}
                    />

                    <div className={style.productTable}>
                      <div
                        className={`${
                          accordionContentShow[lensIndex]?.value
                            ? `${style.contentShow} ${style.transition}`
                            : `${style.contentHide} ${style.transition}`
                        } pdfContentShow`}>
                        <div>
                          {lensValue?.attributes?.map((detailsData: any, detailsIndex: number) => (
                            <AccordionRow
                              key={`row-${detailsData}-${detailsIndex}`}
                              title={detailsData?.name || ''}
                              value={detailsData?.value || ''}
                              type={detailsData?.kind || ''}
                              tooltipText={detailsData?.hint || ''}
                              isToolTip
                              isLensData
                              isLightContent
                            />
                          ))}
                          <div
                            onClick={() => onHeaderClick(lensIndex)}
                            className={`${style.accordionFooter} pdfContentHide`}>
                            {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Container>
        </div>
      </main>
      <ModalComponent
        ref={modalRef}
        isOpen={isModalOpen}
        {...(modalType === 'contact-manufacturer' && { className: style.ModalClass })}
        setIsModalOpen={closeModal}
        modalHeader={getModalContent()?.header || null}
        modalBody={getModalContent()?.body || null}
        modalFooter={getModalContent()?.footer || null}
      />
    </Container>
  );
};
