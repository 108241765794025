export const AISearchData = [
  {
    description: 'ARMONİA LARGE',
    'brand-id': 21626900,
    name: 'ARMONİA LARGE RAVELLO LARGE',
    sku: 'armoni̇a-large-ravello-large',
    id: 1133942987,
    'brand-name': 'AGT AGAÇ SANAYI VE TIC. A.S.',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/agt-agaÃ§-sanayi-ve-tic-a-s_armoniÌ‡a-large-ravello-large_44011b52-a6a9-472b-b8ba-56e77d7dac61.jpg',
    'brand-ident': 'agt-aga-sanayi-ve-tic-as',
    price: null
  },
  {
    description: 'LARGE',
    'brand-id': 21626900,
    name: 'LARGE RAVELLO LARGE',
    sku: 'large-ravello-large-1',
    id: 1133943355,
    'brand-name': 'AGT AGAÇ SANAYI VE TIC. A.S.',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/agt-agaÃ§-sanayi-ve-tic-a-s_large-ravello-large-1_4ddfcecf-90fc-4cac-a5fa-701c521acfb3.jpg',
    'brand-ident': 'agt-aga-sanayi-ve-tic-as',
    price: null
  },
  {
    description:
      'Structure and design are one with the Strut, a confident presence in any room. Functional and fabulous in home or office, our best-selling Strut collection.',
    'brand-id': 14614705,
    name: 'Strut Large Outdoor Table',
    sku: 'strut-large-outdoor-table',
    id: 1610049227,
    'brand-name': 'Blu Dot',
    image:
      'https://sustainabilitydirectory.intertek.com/images/products/Strut%20Large%20Outdoor%20Table-Blu%20Dot-Tables.png',
    'brand-ident': 'blu-dot',
    price: null
  },
  {
    description:
      'Lano is our programme of larger tables, designed to meet the increasing need for practical tabling – from the smallest meeting table to major conference configurations.',
    'brand-id': 29445692,
    name: 'Lano',
    sku: 'lano',
    id: 1610050430,
    'brand-name': 'orangebox',
    image: 'https://sustainabilitydirectory.intertek.com/images/products/Lano-orangebox-Tables.jpg',
    'brand-ident': 'orangebox',
    price: null
  },
  {
    description:
      'A-Table | Square and Rectangle. Square tables available in 18, 21, 24 or 30" square tops with 18, 21 or 24"h legs and 36" square top with 18 or 29"h legs. Rectangle tables available in 48 x 24" rectangular top with 18″h legs and 54 x 18" rectangular top with 29"h legs. Top finishes include veneer, laminate or solid surface. Can be specified with a variety of leg styles.',
    'brand-id': 21742151,
    name: 'A-Table | Square and Rectangle',
    sku: 'a-table-square-and-rectangle',
    id: 1115552325,
    'brand-name': 'Integra Seating',
    image:
      'https://integraseating.com/wp-content/uploads/2022/05/Integra_Coastal-Square-A-Table_600x600.jpg',
    'brand-ident': 'integra-seating',
    price: null
  },
  {
    description:
      'Idear for creating a small conference area or extra workspace. 1.Commercial grade 1’’ thick worksurface and water-.scratch-and dent-resistant. 2.Include modesty panel/integral cable trough and grommets. 3.Include casters(two locking)',
    'brand-id': 791386,
    name: 'Training table',
    sku: 'training-table',
    id: 54318168,
    'brand-name': 'Concept Furniture (Anhui) Co., Ltd.',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/concept-furniture-anhui-co-ltd_training-table_014207ae-0c20-4620-880d-b5f1b6739443.jpg',
    'brand-ident': 'concept-furniture-anhui-co-ltd',
    price: null
  },
  {
    description:
      'Our kid-sized table is crafted of durable hardwood with a smooth, fine grain that makes it durable and easy to clean. Add our matching Carolina chairs to create a fun and comfortable setting for play or crafts.',
    'brand-id': 789006,
    name: 'carolina-large-table-chairs-set-chestnut',
    sku: 'carolina-large-table-chairs-set-chestnut',
    id: 882015482,
    'brand-name': 'Pottery Barn Kids',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/pottery-barn-kids_carolina-large-table-chairs-set-chestnut_5d23d72b-2683-4840-a011-5728e3829293.jpg',
    'brand-ident': 'pottery-barn-kids',
    price: null
  },
  {
    description: 'Table',
    'brand-id': 789467,
    name: '401617 Table 60x30 Black',
    sku: '401617-table-60x30-black',
    id: 1054513349,
    'brand-name': 'Varidesk LLC',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/varidesk-llc_401617-table-60x30-black_539a7eea-e131-4c51-9d46-80e177d88ae3.jpg',
    'brand-ident': 'varidesk-llc',
    price: null
  },
  {
    description: 'Table 60x30 (Butcher Block)',
    'brand-id': 789467,
    name: '46110 - Table 60x30 (Butcher Block)',
    sku: '46110-table-60x30-butcher-block',
    id: 815830616,
    'brand-name': 'Varidesk LLC',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/varidesk-llc_46110-table-60x30-butcher-block_d919f32b-036b-4479-a552-7642a08d7570.jpg',
    'brand-ident': 'varidesk-llc',
    price: null
  },
  {
    description: 'Big Wide Discovery Table',
    'brand-id': 794378,
    name: 'WB1606',
    sku: 'wb1606',
    id: 58830911,
    'brand-name': 'Whitney Brothers Co.',
    image:
      'https://ecomedes-library.s3.us-west-2.amazonaws.com/ul/product-images/whitney-brothers-co_wb1606_78c4dc16-2c2d-47e6-b1d7-4d17c9a6f88b.jpg',
    'brand-ident': 'whitney-brothers-co',
    price: null
  }
];
