import React from 'react';

import { AISearchTitleIcon } from '../../../../assets/svgs/aiSearchTitleIcon';

import style from '../../aiSearch.module.scss';
const ModalHeader: React.FC = () => (
  <div className={style.modalHeaderSection}>
    <div className={style.modalHeaderContent}>
      <AISearchTitleIcon strokeColor={'black'} />
      <h3 className={style.modalHeaderTitle}>AI Search</h3>
    </div>
    <p className={style.modalHeaderDescription}>
      Try our new AI Search assistant to find any product you need in seconds.{' '}
    </p>
  </div>
);

export default ModalHeader;
