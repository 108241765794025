import React from 'react';

import { aiSearchData } from '../../../../utils/aiSearchData';

import style from './aiSearchAccordionHeader.module.scss';
const AiSearchAccordionHeader: React.FC<any> = ({ items }) => (
  <div>
    <div className={style.accordionHeader}>
      <div className={style.accordionHeaderImage}>
        <img src={aiSearchData[0].image} alt="searchImg" className={style.headerImg} />
      </div>
      <div className={style.accordionHeaderContent}>
        <h3 className={style.accordionHeaderContentTitle}>{items?.name || ''}</h3>
        <div className={style.accordionHeaderContentDescription}>{items['brand-name'] || ''}</div>
      </div>
    </div>
  </div>
);

export default AiSearchAccordionHeader;
